import { Component, OnDestroy } from "@angular/core";
import {
  NbMediaBreakpoint,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";
import { delay, takeWhile, withLatestFrom } from "rxjs/operators";

import { StateService } from "../../../@core/utils";
import { shipOwnerLogo } from "../../../shared/constants/constants";
// TODO: move layouts into the framework
@Component({
  selector: "ngx-sample-layout",
  styleUrls: ["./sample.layout.scss"],
  template: `
    <nb-layout>
      <nb-layout-header fixed>
        <ngx-header
          [position]="sidebar.id === 'start' ? 'normal' : 'inverse'"
        ></ngx-header>
      </nb-layout-header>

      <nb-sidebar
        class="menu-sidebar"
        tag="menu-sidebar"
        responsive
        [end]="sidebar.id === 'end'"
      >
        <ng-content select="nb-menu"></ng-content>

      
      </nb-sidebar>

      <nb-layout-column class="main-content">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-column
        start
        class="small"
        *ngIf="layout.id === 'two-column' || layout.id === 'three-column'"
      >
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-column class="small" *ngIf="layout.id === 'three-column'">
        <nb-menu [items]="subMenu"></nb-menu>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class SampleLayoutComponent implements OnDestroy {
  subMenu: NbMenuItem[] = [
    {
      title: "PAGE LEVEL MENU",
      group: true,
    },
    {
      title: "Buttons",
      icon: "ion ion-android-radio-button-off",
      link: "/pages/ui-features/buttons",
    },
    {
      title: "Grid",
      icon: "ion ion-android-radio-button-off",
      link: "/pages/ui-features/grid",
    },
    {
      title: "Icons",
      icon: "ion ion-android-radio-button-off",
      link: "/pages/ui-features/icons",
    },
    {
      title: "Modals",
      icon: "ion ion-android-radio-button-off",
      link: "/pages/ui-features/modals",
    },
    {
      title: "Typography",
      icon: "ion ion-android-radio-button-off",
      link: "/pages/ui-features/typography",
    },
    {
      title: "Animated Searches",
      icon: "ion ion-android-radio-button-off",
      link: "/pages/ui-features/search-fields",
    },
    {
      title: "Tabs",
      icon: "ion ion-android-radio-button-off",
      link: "/pages/ui-features/tabs",
    },
  ];
  layout: any = {};
  sidebar: any = {};

  private alive = true;
  user: IUser;
  currentTheme: string;
  logoLiner: string = "";

  constructor(
    protected stateService: StateService,
    protected menuService: NbMenuService,
    protected themeService: NbThemeService,
    protected bpService: NbMediaBreakpointsService,
    protected sidebarService: NbSidebarService
  ) {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user["roles"].includes("liner") || this.user["roles"].includes("FleetCoordinator") || this.user["roles"].includes("FleetManager")) {
      for (const key in shipOwnerLogo) {
        if (Object.prototype.hasOwnProperty.call(shipOwnerLogo, key)) {
          const element = shipOwnerLogo[key];
          this.user["liner"] === key ? (this.logoLiner = element) : null;
        }
      }
    }
    this.stateService
      .onLayoutState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((layout: string) => (this.layout = layout));

    this.stateService
      .onSidebarState()
      .pipe(takeWhile(() => this.alive))
      .subscribe((sidebar: string) => {
        this.sidebar = sidebar;
      });

    const isBp = this.bpService.getByName("is");
    this.menuService
      .onItemSelect()
      .pipe(
        takeWhile(() => this.alive),
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20)
      )
      .subscribe(
        ([item, [bpFrom, bpTo]]: [
          any,
          [NbMediaBreakpoint, NbMediaBreakpoint]
        ]) => {
          if (bpTo.width <= isBp.width) {
            this.sidebarService.collapse("menu-sidebar");
          }
        }
      );

    this.themeService
      .getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe((theme) => {
        this.currentTheme = theme.name;
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
