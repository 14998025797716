import { DEFAULT_THEME } from './../../@theme/styles/theme.default';
import { async } from '@angular/core/testing';
import { errorCodes } from "./../../shared/constants/constants";
import { ParcAccessService } from "./../../shared/services/parc-access.service";
import { Component, ComponentFactoryResolver, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { translate } from "@ngneat/transloco";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { MyErrorStateMatcher } from "../../shared/app-classes";
import { AuthService } from "../../shared/services/auth.service";
import { NbToastrService, NbGlobalPhysicalPosition } from '@nebular/theme';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  subUser = new Subscription();
  user: IUser;
  loginForm: FormGroup;
  parcs: string[] = [];
  matcher = new MyErrorStateMatcher();
  roles: any;
  Exist: boolean;
  rolesData: any;
  rolesPermission = [];
  Depot: any;
  fetchedData: any;
  firstLoginDisplay : boolean = true;

  /**

 * Initiate Login form and get all parcs id
 * @param auth  Authentication service
 * @param router Router module
 * @param snackBar Snackbar messages
 * @param pas Parc access service
 */
  constructor(
    private auth: AuthService,
    private router: Router,
    private pas: ParcAccessService,
    private toaster: NbToastrService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      password: new FormControl("", [Validators.required]),
    });
    this.pas.getAllParcs().then((parcs) => {
      this.parcs = parcs.map((parc) => parc.id);
    });
  }

  showSuccessToast(message) {
    this.toaster.show(message, "", {
      duration: 2000,
      hasIcon: false,
      position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
    });
  }
  

  /**
   * Redirect to default page according to user role
   * @returns {void}
   */
  async goToDefault() {
    const language = localStorage.getItem("language") === "fr" ? "fr" : "en";
    const successMessage = language === "fr" ? "Connexion réussie" : "Login successful";
    const errorMessage = language === "fr" ? "Echec authentification" : "Login failed: your user ID or password is incorrect";
  
    this.roles = await this.pas.getRolesPremission();
    this.rolesData = this.user.roles;
    this.rolesPermission.push(this.roles.Liner);

    const checker = ([element], rolesPermission) =>
      element !== void 0 ? rolesPermission.includes(element) : true;
    
    if (checker(this.rolesData, this.rolesPermission[0])) {
      if (this.firstLoginDisplay) {
        this.firstLoginDisplay = false;
        this.showSuccessToast(successMessage);
      }
      this.router.navigate(["user/dashboard"]);
    } else if (this.user.roles.includes("admin")) {
      if (this.firstLoginDisplay) {
        this.firstLoginDisplay = false;
        this.showSuccessToast(successMessage);
      }
      this.router.navigate([`user/set-up`]);
    } else {
      this.toaster.show(errorMessage, "", {
        duration: 2000,
        hasIcon: false,
        position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
      });
      this.router.navigate(["/login"]);
    }
  }

  /**
   * @async
   * Check if email and password matches user credentials
   * @returns {Promise<void>}
   */
  async login(): Promise<void> {
    if (this.loginForm.valid) {
      try {
        await this.auth.doEmailLogin(
          this.loginForm.controls["email"].value,
          this.loginForm.controls["password"].value
        );
        this.subUser = this.auth.user.subscribe(async (u) => {
          if (u) {
            this.user = u;
            await this.goToDefault();
            this.subUser.unsubscribe();
          }
        });
      } catch (error) {
        const message = errorCodes[error.code]
          ? errorCodes[error.code]
          : translate("Echec authentification");
        this.toaster.show(message, "", {
          duration: 2000,
          hasIcon: false,
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
        });
      }
    }
  }
  /**
   * Get user and redirect to default page
   * @returns {void}
   */
  ngOnInit(): void {
    this.subUser = this.auth.user.subscribe(async (u) => {
      if (u) {
        this.user = u;
        await this.goToDefault();
      }
    });
  }

  ngOnDestroy(): void {
    this.subUser.unsubscribe();
  }
}
