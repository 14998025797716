import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { translate } from "@ngneat/transloco";

import { MatSnackBar } from "@angular/material";
import { map, take, tap } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { SnackBarComponent } from "../../pages/snack-bar/snack-bar.component";
import { ParcAccessService } from "../services/parc-access.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  rolesData : any;
  constructor(
    private auth: AuthService,
    private router: Router,
    public snackBar: MatSnackBar,
    private pas: ParcAccessService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const roleInURL = next.url[0].path;
    let otherRole = false;
   this.rolesData = this.auth.roles || [];

  // console.log("roles firebase", this.rolesData)
    return this.auth.user.pipe(
      take(1),
      map((user) => { 
      //   console.log("roles user",user.roles)
        if (!user || !user.roles) {
          this.router.navigate(["/auth/login"]);
          return false;
        } else if 
          (user && this.rolesData.some(element => user.roles.includes(element)) || user.roles.includes("admin")
        ) {
          return true;
        }else if (user) {
          otherRole = true;
          this.router.navigate(["/auth/login"]);
          return false;
          
        } 
      }),
      tap((loggedIn) => {
        if (!loggedIn) {
          if (otherRole) {
            this.auth.signOut();
            this.snackBar.openFromComponent(SnackBarComponent, {
              data: {
                message: translate(
                  "Access Denied Try your credentials in Mobile App"
                ),
                icon: "check_circle_outline",
              },
              panelClass: ["style-success"],
              duration: 2000,
            });
          }
          this.auth.redirectRouteSnapshot = next;
          this.router.navigate(["/auth/login"]);
        }
      })
    );
  }
}