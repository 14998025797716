import { async } from "@angular/core/testing";
import { Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { DataAccessHelpersService } from "./data-access-helpers.service";
import { firestore } from "firebase";
import { AngularFireAuth } from "@angular/fire/auth";
import { MatTableDataSource } from "@angular/material";
import { environment } from "../../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class ParcAccessService {
  emrParcs: any;
  parcsByLinerDefault: any;
  parcsDetailByLiner: any;
  filteredParcsByLiner: any;
  // var for users //
  parcsByLiner: any;
  userByLiner: any;
  filteredUsers: any;
  users: any;

  private parcSource = new BehaviorSubject("");
  estimateDepotDEtails$ = new BehaviorSubject([]);
  UsersDetails$ = new BehaviorSubject([]);
  currentParc = this.parcSource.asObservable();
  API_ENDPOINT = environment.API_ENDPOINT;

  headers = { "Content-Type": "application/json" };

  async setEstimateDepotDEtails(liner) {
    const allParcsData = await this.getAllParcs();
    let parcsByLinerDefault = [];
    allParcsData.forEach((parc) => {
      (parc["ViewedByLiner"] || []).forEach((element) => {
        if (element.armateur.includes(liner))
          parcsByLinerDefault.push(parc["id"]);
      });
    });
    this.parcsByLinerDefault = parcsByLinerDefault;
    let tab = [];
    allParcsData.forEach((elem) => {
      this.parcsByLinerDefault.forEach((element) => {
        if (elem["id"].includes(element)) {
          tab.push(elem);
        }
      });
    });
    const filteredArray = [];
    tab.forEach((slot) => {
      const isExists = filteredArray.some((s) => s["id"] === slot["id"]);
      if (!isExists) filteredArray.push(slot);
    });
    this.parcsDetailByLiner = [];
    (filteredArray || []).forEach((element) => {
      (element["ViewedByLiner"] || []).forEach((el) => {
        this.parcsDetailByLiner.push({
          id: element["id"],
          armateur: el["armateur"],
          pays: el["pays"],
          region: el["region"],
          agency: el["NameOfLocalAgency"],
          allowEstimateApproval: el["allowEstimateApproval"],
          allowAutomatedApproval: el["allowAutomatedApproval"],
          authorizedEstimateConditions: el["authorizedEstimateConditions"]
        });
      });
    });
    this.filteredParcsByLiner = this.parcsDetailByLiner.filter(
      (item) => item.armateur === liner
    );
    this.estimateDepotDEtails$.next(this.filteredParcsByLiner);
  }

  async displayUsers(liner) {
    this.getRoles().subscribe(async (docData) => {
      let tab = [];
      const usersLiner = [];
      const users = await this.getUsersByLiner();
      let parcsByLiner = [];
      const allParcsData = await this.getAllParcs();
      allParcsData.forEach((parc) => {
        (parc["ViewedByLiner"] || []).forEach((element) => {
          if (element.armateur.includes(liner)) parcsByLiner.push(parc["id"]);
        });
      });
      this.parcsByLiner = parcsByLiner;
      users.forEach((doc) => {
        (this.parcsByLiner || []).forEach((elm) => {
          if ((doc["allParcs"] || []) && (doc["allParcs"] || []).includes(elm))
            usersLiner.push(doc);
        });
      });
      this.userByLiner = [];
      usersLiner.forEach((elm) => {
        if (!this.userByLiner.includes(elm)) {
          this.userByLiner.push(elm);
        }
      });
      this.userByLiner.forEach((elem) => {
        if (docData.Liner.some((element) => elem["roles"].includes(element)))
          tab.push(elem);
      });
      this.users = tab;
      const filteredUsers = [];
      this.users.forEach((element) => {
        if (element.liner === liner) filteredUsers.push(element);
      });
      this.filteredUsers = filteredUsers;
      this.UsersDetails$.next(this.filteredUsers);
    });
  }

  /* async editTogglers() {
    // do some stuff
  } */

  getIdToken() {
    return this.afAuth.auth.currentUser.getIdToken(true);
  }

  changeParc(parc: any) {
    this.parcSource.next(parc);
  }
  getMostUsedCodes(parc: string) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/getTopCodes/?parc=${parc}`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "GET",
        });
      })
      .catch((err) => Promise.reject(err));
  }
  getCredentials(parc: string) {
    return this.dhs.getDocumentOnce(`externalCredentials/${parc}`);
  }
  getAllCredentials() {
    return this.dhs.getCollectionOnce("externalCredentials");
  }
  sendCredentials(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/setupCredentials`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }
  sendMove(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/moves`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }
  sendInterchange(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/sendInterchange`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  saveLog(payload) {
    return this.getIdToken()
      .then((idToken) => {
        return fetch(`${this.API_ENDPOINT}/logs`, {
          headers: { ...this.headers, Authorization: idToken },
          method: "POST",
          body: JSON.stringify(payload),
        });
      })
      .catch((err) => Promise.reject(err));
  }

  getInspectionTaskBySN(parcId: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parcId}/tasks`)
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "inspection")
      .get();
  }
  getCustomersOnce() {
    return this.dhs.getCollectionOnce("customers");
  }
  getAllParcs(): Promise<IParc[]> {
    return this.dhs.getCollectionOnce("yards");
  }
  getYardOnceById(id: string): Promise<IParc> {
    return this.dhs.getDocumentOnce(`yards/${id}`);
  }
  getYardSubscribe(id: string): Observable<IParc> {
    return this.dhs.getDocument(`yards/${id}`);
  }
  setYardFournisseurs(
    parc: string,
    fournId: string,
    data: Partial<IFournisseur>
  ) {
    return this.afs
      .collection(`yards/${parc}/fournisseurs`)
      .doc(fournId)
      .set(data);
  }
  setYardClients(parc: string, clientId: string, data: Partial<IClient>) {
    return this.afs.collection(`yards/${parc}/clients`).doc(clientId).set(data);
  }
  getInterchangeDocumentsDone(parc: string): Observable<IInterchange[]> {
    return this.dhs.getCollectionWhereByOrderWithLimit(
      `yards/${parc}/interchanges`,
      [{ left: "printable", operator: "==", right: true }],
      "date",
      "desc",
      600
    );
  }
  getLastReservedPiece(parcId: string, refPiece: string) {
    return this.afs
      .collection(`yards/${parcId}/spare-parts-inventory`)
      .ref.where("refPiece", "==", refPiece)
      .orderBy("lastDecrement", "desc")
      .get();
  }
  getInterchangeByNumSerie(parc: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parc}/interchanges`)
      .ref.where("numSerie", "==", numSerie)
      .get();
  }
  getLatestInterchangeByNumSerie(parc: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parc}/interchanges`)
      .ref.where("numSerie", "==", numSerie)
      .orderBy("date", "desc")
      .limit(1)
      .get();
  }
  getDevisByNumSerie(parc: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parc}/devis`)
      .ref.where("numSerie", "==", numSerie)
      .get();
  }
  setOrder(parc: string, order) {
    return this.afs.collection(`yards/${parc}/orders`).add(order);
  }
  getAllOrders(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/orders`);
  }
  getAllBills(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/devis`);
  }
  getOrders(parc: string) {
    return this.dhs.getCollectionOrderBy(
      `yards/${parc}/orders`,
      "createdAt",
      "desc"
    );
  }

  getYardClients(parc: string): Observable<IClient[]> {
    return this.dhs.getCollection(`yards/${parc}/clients`);
  }
  getYardClientsOnce(parc: string): Promise<IClient[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/clients`);
  }

  getInterchangeDocumentsPending(parc: string): Observable<IInterchange[]> {
    return this.dhs.getCollectionWhere(`yards/${parc}/interchanges`, [
      { left: "printable", operator: "==", right: false },
    ]);
  }

  getIncompletedGatesOnce(parc: string): Promise<any[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/incompleteGate`);
  }
  getIncompletedGates(parc: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${parc}/incompleteGate`);
  }

  getYardFournisseur(parc: string): Observable<IFournisseur[]> {
    return this.dhs.getCollection(`yards/${parc}/fournisseurs`);
  }
  getAllTransportersOnce(parc: string): Promise<any[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/fournisseurs`);
  }
  getYardFournisseurOnce(parc: string, fournId: string): Promise<any> {
    return this.dhs.getDocumentOnce(`yards/${parc}/fournisseurs/${fournId}`);
  }
  getTruckingCompanyByPlate(parc: string, plate: string) {
    return this.afs
      .collection("yards")
      .doc(parc)
      .collection("fournisseurs")
      .ref.where("trucks", "array-contains", plate)
      .limit(1)
      .get();
  }
  getTruckingCompanyByParc(parc: string) {
    return this.afs
      .collection("yards")
      .doc(parc)
      .collection("fournisseurs")

      .get();
  }
  deleteYardFournisseur_Client(path: string) {
    return this.afs.doc(path).delete();
  }
  deleteContract(path: string) {
    return this.afs.doc(path).delete();
  }
  updateYardFournisseur(path: string, data: Partial<IFournisseur>) {
    return this.afs.doc(path).update(data);
  }
  updateYardClient(parc: string, id: string, data: Partial<IClient>) {
    return this.afs.doc(`yards/${parc}/clients/${id}`).update(data);
  }
  setYardData(parc: string, data: Partial<IParc>) {
    return this.afs.doc(`yards/${parc}`).update(data);
  }

  postContainer(parc: string, numSerie: string, data: IContainer) {
    return this.afs
      .collection(`yards/${parc}/containers`)
      .doc(`${numSerie}`)
      .set(data);
  }
  postTask(parc: string, data: Partial<ITask>) {
    return this.afs.doc(`yards/${parc}`).collection("tasks").add(data);
  }
  postTaskwithID(parc: string, id: string, data: Partial<ITask>) {
    return this.afs.doc(`yards/${parc}`).collection("tasks").doc(id).set(data);
  }
  setStackingRule(parcId: string, bayId: string, data) {
    return this.afs
      .collection(`yardPlans/${parcId}/stackingRules`)
      .doc(bayId)
      .set(data);
  }
  deleteStackingRule(parcId: string, bayId: string) {
    return this.afs.doc(`yardPlans/${parcId}/stackingRules/${bayId}`).delete();
  }
  getStackingRule(parcId: string, bayId: string) {
    return this.dhs.getDocumentOnce(
      `yardPlans/${parcId}/stackingRules/${bayId}`
    );
  }
  getStackingRules(parcId: string) {
    return this.dhs.getCollectionOnce(`yardPlans/${parcId}/stackingRules`);
  }
  updateStackingRule(parcId: string, bayId: string, data) {
    return this.afs
      .doc(`yardPlans/${parcId}/stackingRules/${bayId}`)
      .update(data);
  }

  postInterchange(parc: string, data: Partial<IInterchange>, taskId: string) {
    return this.afs
      .doc(`yards/${parc}`)
      .collection("interchanges")
      .doc(taskId)
      .set(data);
  }
  getYardPlanOnce(yardId: string): Promise<IPlan> {
    return this.dhs.getDocumentOnce(`/yardPlans/${yardId}`);
  }
  getYardPlan(yardId: string): Observable<IPlan> {
    return this.dhs.getDocument(`yardPlans/${yardId}`);
  }

  updateYardPlan(yardId: string, plan: Partial<IPlan>) {
    return this.afs.doc(`/yardPlans/${yardId}`).update(plan);
  }

  autoCompleteForm(parc: string) {
    return this.afs
      .collection(`/yards/${parc}/tasks`)
      .ref.where("type", "==", "gateFromOCR")
      .where("inProgress", "==", false)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
  }
  setCurrencyRate(id, data) {
    return this.afs.collection("exchangeRates").doc(id).set(data);
  }
  updateCurrencyRate(id, data) {
    return this.afs.collection("exchangeRates").doc(id).update(data);
  }
  deleteCurrencyRate(id) {
    return this.afs.collection("exchangeRates").doc(id).delete();
  }
  getCurrencyRates() {
    return this.dhs.getCollection("exchangeRates");
  }
  getCurrencyRate(id) {
    return this.dhs.getDocumentOnce(`exchangeRates/${id}`);
  }
  updateOCRTask(parc: string, docId: string, data: Partial<ITask>) {
    return this.afs.doc(`yards/${parc}/tasks/${docId}`).update(data);
  }
  deleteTask(parc: string, docId: string) {
    return this.afs.doc(`yards/${parc}/tasks/${docId}`).delete();
  }
  deleteDevis(parc: string, docId: string) {
    return this.afs.doc(`yards/${parc}/devis/${docId}`).delete();
  }
  getPlacementTasks(parc: string): Observable<ITask[]> {
    return this.dhs.getCollectionWhere(`yards/${parc}/tasks`, [
      { left: "type", operator: "in", right: ["placement"] },
    ]);
  }
  getPlacementTasksOnce(parc: string) {
    return this.afs
      .collection(`yards/${parc}/tasks`)
      .ref.where("type", "==", "placement")
      .get();
  }

  getStockInventroyByLastDec(parc: string): Observable<any[]> {
    return this.dhs.getCollectionOrderBy(
      `yards/${parc}/spare-parts-inventory`,
      "lastDecrement",
      "desc"
    );
  }
  getStockInventroy(parc: string): Observable<any[]> {
    return this.dhs.getCollection(`yards/${parc}/spare-parts-inventory`);
  }
  getStockInventroyOnce(parc: string): Promise<any[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/spare-parts-inventory`);
  }
  getRelatedTasks(yardId, billId) {
    return this.afs
      .collection("yards")
      .doc(yardId)
      .collection("tasks")
      .ref.where("estimateId", "==", billId)
      .get();
  }
  getPiece(parc, refP) {
    return this.afs
      .collection(`yards/${parc}/spare-parts-inventory`)
      .ref.where("refPiece", "==", refP)
      .orderBy("integrationTime")
      .get();
  }
  getPendingTasks(parc, billId) {
    return this.afs
      .collection(`yards/${parc}/pendingTasks`)
      .ref.where("estimateId", "==", billId)
      .get();
  }
  getPieceInRepairTask(parcId) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("tasks")
      .ref.where("type", "==", "repair")
      .get();
  }
  getPieceByVendor(parc, refP, vendor, stockUnit?) {
    return (
      this.afs
        .collection(`yards/${parc}/spare-parts-inventory`)
        .ref.where("refPiece", "==", refP)
        .where("vendor", "in", [vendor, ""])
        //.where("stockUnit",'==',stockUnit)
        .orderBy("integrationTime")
        .limit(1)
        .get()
    );
  }
  getOutOfStockParts(parc) {
    return this.afs
      .collection(`yards/${parc}/spare-parts-inventory`)
      .ref.where("availableQuantity", "<=", 0)
      .get();
  }

  setPiece(parc, data) {
    return this.afs.collection(`yards/${parc}/spare-parts-inventory`).add(data);
  }
  decrementStock(path, decr) {
    const decrement = firestore.FieldValue.increment(decr * -1);
    return this.afs.doc(path).update({ availableQuantity: decrement });
  }
  getAllTasks(parc: string): Promise<ITask[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/tasks`);
  }

  setBooking(parc: string, id: string, data: Partial<any>) {
    return this.afs.collection(`yards/${parc}/booking`).doc(id).set(data);
  }
  getBooking(parc: string): Observable<IBooking[]> {
    return this.dhs.getCollectionOrderBy(
      `yards/${parc}/booking`,
      "createdAt",
      "desc"
    );
  }
  getBookingOnce(parc: string): Promise<IBooking[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/booking`);
  }
  getSpecificBooking(parc: string, idBooking: string) {
    return this.dhs.getDocumentOnce(`yards/${parc}/booking/${idBooking}`);
  }
  deleteBooking(parc: string, id: string) {
    return this.afs.doc(`yards/${parc}/booking/${id}`).delete();
  }
  updateBooking(parc: string, id: string, data: Partial<IBooking>) {
    return this.afs.doc(`yards/${parc}/booking/${id}`).update(data);
  }
  deleteReport(path: string) {
    return this.afs.doc(path).delete();
  }
  updateReport(path: string, data: Partial<IAuthorizedReports>) {
    return this.afs.doc(path).update(data);
  }
  setReport(parc: string, data: IAuthorizedReports) {
    return this.afs.collection(`yards/${parc}/reports`).add(data);
  }
  getReports(parc: string): Observable<IAuthorizedReports[]> {
    return this.dhs.getCollection(`yards/${parc}/reports`);
  }
  getRepairTaskBySN(parcId: string, numSerie: string) {
    return this.afs
      .collection(`yards/${parcId}/tasks`)
      .ref.where("numSerie", "==", numSerie)
      .where("type", "==", "repair")
      .limit(1)
      .get();
  }

  getReportsOnce(parc: string): Promise<IAuthorizedReports[]> {
    return this.dhs.getCollectionOnce(`yards/${parc}/reports`);
  }
  getAllReports(parc: string): Promise<any> {
    return this.dhs.getCollectionOnce(`yards/${parc}/reports`);
  }
  getDepotsContracts(
    parc: string,
    client: string,
    type: string
  ): Observable<
    IShuntingContract[] | IHandlingContract[] | IStorageContract[]
  > {
    return this.dhs.getCollection(`yards/${parc}/clients/${client}/${type}`);
  }
  getDepotsContractsOnce(
    parc: string,
    client: string,
    type: string
  ): Promise<any[]> {
    return this.dhs.getCollectionOnce(
      `yards/${parc}/clients/${client}/${type}`
    );
  }
  setDepotsContracts(
    parc: string,
    client: string,
    type: string,
    data: Partial<IStorageContract | IShuntingContract | IHandlingContract>
  ) {
    return this.afs
      .collection(`yards/${parc}/clients/${client}/${type}`)
      .add(data);
  }
  setShuntingContracts(
    parc: string,
    client: string,
    data: Partial<IShuntingContract>
  ) {
    return this.afs
      .collection(`yards/${parc}/clients/${client}/shuntingContracts`)
      .add(data);
  }
  updateDepotsContract(
    path: string,
    data: Partial<IShuntingContract | IHandlingContract | IStorageContract>
  ) {
    return this.afs.doc(path).set(data);
  }
  getPreNotifs(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/prenotif`);
  }

  getPreNotifDetails(parcId: string, preNotifId: string) {
    return this.dhs.getDocumentOnce(`yards/${parcId}/prenotif/${preNotifId}`);
  }

  getPrenotifDetails(parcId: string, id: string) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("subPrenotifs")
      .ref.where("prenotifId", "==", id)
      .get();
  }
  updatePreNotifDetailByPath(path: string, data) {
    return this.afs.doc(path).update(data);
  }
  getPreNotifRT(parcId: string, operator: string, type: string | string[]) {
    return this.dhs.getCollectionWhereByOrder(
      `yards/${parcId}/prenotif`,
      [{ left: "type", operator, right: type }],
      "createdAt",
      "desc"
    );
  }
  getBillablePreNotifRT(parcId: string) {
    return this.dhs.getCollectionWhere(`yards/${parcId}/prenotif`, [
      { left: "type", operator: "in", right: ["lavage", "PTI"] },
    ]);
  }
  getSubPrenotifs(parcId: string) {
    return this.dhs.getCollectionOnce(`yards/${parcId}/subPrenotifs`);
  }
  getPosTasks(parcId: string) {
    return this.dhs.getCollectionWhere(`yards/${parcId}/tasks`, [
      { left: "type", operator: "==", right: "Positioning" },
    ]);
  }

  searchForMatchingPrenotif(parcId: string, data) {
    return this.afs
      .collection("yards")
      .doc(parcId)
      .collection("subPrenotifs")
      .ref.where("conType", "==", data.conType)
      .where("conSize", "==", data.conSize)
      .where("transporter", "==", data.transporter.id)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();
  }
  getCounter(parcId: string, counterName: string) {
    return this.dhs.getDocument(`yards/${parcId}/counters/${counterName}`);
  }

  setPreNotif(parcId: string, id: string, data: Partial<IPreNotif>) {
    return this.afs.collection(`yards/${parcId}/prenotif`).doc(id).set(data);
  }
  setPreNotifDetail(
    parcId: string,
    preNotifId: string,
    containerId: string,
    data: any
  ) {
    return this.afs
      .collection(`yards/${parcId}/prenotif`)
      .doc(preNotifId)
      .collection("done")
      .doc(containerId)
      .set(data);
  }
  updatePreNotif(parcId: string, docId: string, data) {
    return this.afs.doc(`yards/${parcId}/prenotif/${docId}`).update(data);
  }
  deletePreNotif(parcId: string, docId: string) {
    return this.afs.doc(`yards/${parcId}/prenotif/${docId}`).delete();
  }
  deleteOrder(path: string) {
    return this.afs.doc(path).delete();
  }
  async getRolesPremission() {
    return this.dhs.getDocumentOnce(`constants/roles`);
  }

  getRolesOnce() {
    return this.dhs.getDocumentOnce(`constants/roles`);
  }

  getLinersOnce() {
    return this.dhs.getDocumentOnce(`constants/Liners`);
  }
  getAllreports() {
    return this.dhs.getDocumentOnce(`constants/allReports`);
  }
  getRepportPerUser() {
    return this.dhs.getDocumentOnce(`constants/reportPerUserRole`);
  }
  addLinerData(data: any) {
    return this.afs.collection(`constants`).doc("Liners").update(data);
  }

  addRole(data: any) {
    return this.afs.collection(`constants`).doc("roles").update(data);
  }
  addReport(data: any) {
    return this.afs
      .collection(`constants`)
      .doc("reportPerUserRole")
      .update(data);
  }
  getRoles() {
    return this.dhs.getDocument(`constants/roles`);
  }
  getRepportPeruser() {
    return this.dhs.getDocument(`constants/reportPerUserRole`);
  }
  addReportData(data: any) {
    return this.afs.collection(`constants`).doc("allReports").update(data);
  }
  /*   getParcsByLinerOwnership(liner) {
    return this.afs
      .collection("yards")
      .ref.where("shipOwnerClient", "==", liner)
      .get();
  } */

  getUsersByLiner(): Promise<IUser[]> {
    return this.dhs.getCollectionOnce("users");
  }

  constructor(
    private dhs: DataAccessHelpersService,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {}
}
